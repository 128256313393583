<template>
  <div class="w-100 h-100">
    <div :class="`${$route.params.transaction ? 'content-40' : 'content-120'}`" v-if="transactionInfo">
      <div :class="`${$isMobile() ? '' : 'row'} h-100 center`">
        <span class="col-sm" v-if="!$isMobile()">&nbsp;</span>
        <div :class="`${$isMobile() ? '' : 'col-sm-6'} h-100 box-white center`">
          <p :class="`${$isMobile() ? 'mt-10' : 'mt-20'} font-20 font-bold color-drink`">{{ `Gesamtbetrag: ${getPriceFormat(transactionInfo.totalPrice)}` }}</p>
          <div :class="$isMobile() ? 'mt-10' : 'mt-30'">
            <div id="paypal-button-container" :class="$isMobile() ? 'mx-5 mt-20' : 'mx-20 mt-30'" v-if="!elementsOptions.clientSecret"></div>
            <p class="mt-5 font-15 color-red" v-if="errorMessage">{{ errorMessage }}</p>
            <div :class="$isMobile() ? 'mx-5 mt-5' : 'mx-20 mt-30'" v-if="hidePaymentMethods && elementsOptions.clientSecret">
              <StripeElementPayment
                ref="stripeElementPayment"
                :pk="publishableKey"
                :elements-options="elementsOptions"
                :confirm-params="confirmParams"
                @loading="onStripeLoading"
              />
              <div class="mt-20 d-flex justify-content-end">
                <v-btn class="button-drink" @click="stripePay()">
                  <span class="px-10">{{ $t('pay') }}</span>
                </v-btn>
              </div>
            </div>
          </div>
          <div :class="$isMobile() ? 'mx-5 mt-20' : 'mx-20 mt-30'" v-if="!hidePaymentMethods">
            <div class="my-10 form-check vertical-center">
              <input class="form-check-input pointer" type="radio" id="paypal" name="paymentMethod" v-model="paymentMethod" value="paypal">
              <label class="form-check-label ml-2 w-100 vertical-center pointer" for="paypal">
                <span class="font-15">PayPal/SEPA</span>
                <img class="ml-auto" :height="30" :src="assets.paypal" />
                <img class="ml-1" :height="30" :src="assets.sepa" />
              </label>
            </div>
            <div class="mt-10 form-check vertical-center">
              <input class="form-check-input pointer" type="radio" id="card" name="paymentMethod" v-model="paymentMethod" value="card">
              <label class="form-check-label ml-2 w-100 vertical-center pointer" for="card">
                <span class="font-15">Credit Card</span>
                <img class="ml-auto" :height="30" :src="assets.card" />
              </label>
            </div>
            <div class="mt-10 form-check vertical-center">
              <input class="form-check-input pointer" type="radio" id="klarna" name="paymentMethod" v-model="paymentMethod" value="klarna">
              <label class="form-check-label ml-2 w-100 vertical-center pointer" for="klarna">
                <span class="font-15">Sofort/Klarna</span>
                <img class="ml-auto" :height="30" :src="assets.sofort" />
                <img class="ml-1" :height="30" :src="assets.klarna" />
              </label>
            </div>
            <div class="mt-10 form-check vertical-center" v-if="false">
              <input class="form-check-input pointer" type="radio" id="giropay" name="paymentMethod" v-model="paymentMethod" value="giropay">
              <label class="form-check-label ml-2 w-100 vertical-center pointer" for="giropay">
                <span class="font-15">Giropay</span>
                <img class="ml-auto" :height="30" :src="assets.giropay" />
              </label>
            </div>
            <div class="mt-30 d-flex justify-content-end">
              <v-btn class="w-100 button-drink" @click="doNext()" :disabled="paymentMethod === ''">
                <span class="px-10">{{ $t('next') }}</span>
              </v-btn>
            </div>
          </div>
        </div>
        <span class="col-sm" v-if="!$isMobile()">&nbsp;</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .content-40 {
    height: calc(100vh - 40px);
    margin: 20px;
  }
  .content-120 {
    height: calc(100vh - 120px);
    margin: 20px;
  }
</style>

<script>
import { loadScript } from "@paypal/paypal-js";
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import { httpsCallable } from 'firebase/functions';
import { firebaseAuth, functions } from '../../../main';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getPriceFormat } from '../../../functions';

import logoLight from '@/assets/logo/logo_light.png';
import paypal from '@/assets/images/paypal.png';
import sepa from '@/assets/images/sepa.png';
import card from '@/assets/images/card.png';
import sofort from '@/assets/images/sofort.png';
import klarna from '@/assets/images/klarna.png';
import giropay from '@/assets/images/giropay.png';

export default {
  name: 'DrinkCheckout',
  components: {
    StripeElementPayment
  },
  data() {
    return {
      clientId: '',
      publishableKey: '',
      elementsOptions: {
        clientSecret: ''
      },
      confirmParams: {
        return_url: `${window.location.origin}/payment/stripe_return`,
      },
      paymentMethod: '',
      hidePaymentMethods: false,
      assets: {
        logoLight,
        paypal,
        sepa,
        card,
        sofort,
        klarna,
        giropay
      },
      errorMessage: '',
      transactionInfo: null,
      loader: null
    }
  },
  mounted() {
    const transactionString = Buffer.from(this.$route.params.transaction, 'base64').toString('utf8');
    const transactionInfo = JSON.parse(transactionString);
    if (!transactionInfo) {
      this.$toast.error('Invalid data!');
      this.$router.push(`/payment/confirm/Error`);
      return;
    }
    if (!transactionInfo.buyerId) {
      transactionInfo.buyerId = this.$store.state.myId;
    }
    // mauser1, dhero
    if (transactionInfo.buyerId === 'zBLs9VEdIB59dcqEKz4b' || transactionInfo.buyerId === 'jkVhKenDiDzpMsicSDgT') { // Sandbox
      this.clientId = 'AXt58SUzGK4aSuR_74_7CykDQKWbQZn2yoKEU-8qKAedUEr0n7cGvp6TLaiDxQXXDGu3mjUfXQZzZKBy';
      this.publishableKey = 'pk_test_51MjoH5Cevq4112L6lkVUoNJn7BAm3Zh64d0abp5pjXHSeZNbAhFllsBqHLscM5bGls5cbYClnTR6DcHyYQIhuvcR00EDiqTwcP';
    } else {  // Production
      this.clientId = 'AXMhuViBVbvcc-xGe_VPeTmB-NIF3oqV9qXAgOWHJwu-4qsFBOfkOs8OMLEnMGM7Uevk1TWJRyf9VDOy';
      this.publishableKey = 'pk_live_51MjoH5Cevq4112L6QkReryZd0eGpOMy0uX52vR2w2y1RhGWx5LjsXY4Sz8DQWe2oD24V7hzy4sVFKhsgP7epAE3O00wjkQ182U';
    }
    if (firebaseAuth.currentUser) {
      this.initialize(transactionInfo);
    } else {
      this.showLoading();
      signInWithEmailAndPassword(firebaseAuth, 'guest@eventbox.com', 'eventbox0225')
      .then(result => {
        this.hideLoading();
        this.initialize(transactionInfo);
      }).catch(error => {
        this.hideLoading();
        this.errorMessage = this.$t('alert_unexpected_error');
      });
    }
  },
  methods: {
    getPriceFormat,
    onStripeLoading(isLoading) {
      if (isLoading) {
        this.showLoading();
      } else {
        this.hideLoading();
      }
    },
    initialize(transactionInfo) {
      this.transactionInfo = transactionInfo;
    },
    doNext() {
      localStorage.setItem('eventboxPaymentMethod', this.paymentMethod);
      if (this.transactionInfo.buyerId) {
        this.hidePaymentMethods = true;
        if (this.paymentMethod === 'paypal') {
          this.loadPayPal();
        } else {
          this.getPaymentIntentTask();
        }
      } else {
        this.$toast.error('Invalid data!');
        this.$router.push(`/payment/confirm/Error`);
      }
    },
    stripePay() {
      this.$refs.stripeElementPayment.submit()
    },
    loadPayPal() {
      loadScript({
        'client-id': this.clientId,
        'currency': 'EUR',
        'disable-funding': 'card,credit,venmo,bancontact,eps,giropay,ideal,mybank,p24,sofort'
      }).then(paypal => {
        const self = this;
        paypal.Buttons({
          style: {
            shape: 'pill',
            color: 'blue',
            layout: 'vertical',
            label: 'pay'
          },
          createOrder: async function(data, actions) {
            return await self.createPayPalOrderTask(data.paymentSource);
          },
          onApprove: async function(data, actions) {
            return await self.capturePayPalOrderTask(data.orderID);
          },
          onCancel: function(data, actions) {
          },
          onError: function(error) {
            self.errorMessage = 'Unexpected error occurs - ' + error;
          }
        })
        .render('#paypal-button-container')
        .catch(error => {
          this.errorMessage = 'Failed to render the PayPal.';
        });
      })
      .catch(error => {
        this.errorMessage = 'Failed to load the PayPal.';
      });
    },
    showLoading() {
      this.loader = this.$loading.show({
        loader: 'spinner',
        backgroundColor: '#636F83',
        color: '#A10559'
      });
    },
    hideLoading() {
      if (this.loader) {
        this.loader.hide();
      }
    },
    getPaymentIntentTask() {
      const params = JSON.parse(JSON.stringify(this.transactionInfo));
      params['paymentMethod'] = this.paymentMethod;
      this.showLoading();
      const func = httpsCallable(functions, 'setDrinkTransactionTempInfo');
      func(JSON.stringify(params)).then(response => {
        this.hideLoading();
        if (typeof response.data === 'string') {
          this.elementsOptions.clientSecret = response.data;
        } else {
          this.errorMessage = `Server error occurs.\n${response.data}`;
        }
      }).catch(error => {
        this.hideLoading();
        this.errorMessage = `Unexpected error occurs.`;
      });
    },
    async createPayPalOrderTask(paymentMethod) {
      const params = JSON.parse(JSON.stringify(this.transactionInfo));
      params['paymentMethod'] = paymentMethod;
      this.showLoading();
      const func = httpsCallable(functions, 'setDrinkTransactionTempInfo');
      return func(JSON.stringify(params)).then(response => {
        this.hideLoading();
        if (typeof response.data === 'string') {
          return response.data;
        } else {
          this.errorMessage = `Server error occurs.\n${response.data}`;
          return null;
        }
      }).catch(error => {
        this.hideLoading();
        this.errorMessage = `Unexpected error occurs.`;
        return null;
      });
    },
    async capturePayPalOrderTask(orderId) {
      const params = JSON.parse(JSON.stringify(this.transactionInfo));
      params['captureOrder'] = true;
      params['orderId'] = orderId;
      this.showLoading();
      const func = httpsCallable(functions, 'buyDrink');
      return func(JSON.stringify(params)).then(response => {
        this.hideLoading();
        if (response.data === this.RESULT_SUCCESS) {
          window.location.href = `${window.location.origin}/payment/confirm/Success`;
        } else {
          this.errorMessage = `Capture error occurs.\n${response.data}\n\n${orderId}`;
        }
      }).catch(error => {
        this.hideLoading();
        this.errorMessage = `Unexpected error occurs.\n${orderId}`;
      });
    }
  }
};
</script>